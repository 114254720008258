import Head from "next/head";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/future/image";
import dynamic from "next/dynamic";
import { ThemeProvider, type ThemeProviderProps } from "next-themes";
import { WifAppProvider } from "@business-finland/wif-ui-lib/dist/context";
import { useMemo } from "react";

import { __APP_HOST_PUBLIC__, META_TITLE_SUFFIX } from "../../constants/app";
import {
  localesEmployers,
  localesTalent,
  ogLocaleMap,
} from "../../constants/locale";
import { MGNL } from "../../constants/magnolia";
import { EmployerSiteContextProvider } from "../../contexts/EmployerSiteContext";
import { PageContentContextProvider } from "../../contexts/PageContentContext";
import { WifSavedItemsToStorageProvider } from "../../contexts/SaveItemsToStorageContext";
import { ViewportContextProvider } from "../../contexts/ViewportContext";
import { MagnoliaTemplate } from "../../magnolia.config";
import { formatAssetLink } from "../../util/magnolia";
import { WifNavLinks } from "../../types/wif-next";
import Footer from "../Footer";
import Navigation from "../Navigation";
import RouteProgressBar from "./RouteProgressBar";
import styles from "./Layout.module.scss";

const AinoChatButton = dynamic(() => import("../AinoChatButton"), {
  ssr: false,
});

export interface ILayoutProps {
  title: string;
  links: WifNavLinks;
  children?: React.ReactNode;
  description?: string;
  keywords?: string[];
  isHomepage?: boolean;
  isEmployerSite?: boolean;
  page: Wif.Mgnl.Page | null;
}

export function Layout(props: ILayoutProps): JSX.Element {
  const { links, children, isEmployerSite = false, page } = props;

  const themeProviderConfig: ThemeProviderProps = useMemo(
    () => ({
      themes: ["default", "employer"],
      defaultTheme: isEmployerSite ? "employer" : "default",
      forcedTheme: isEmployerSite ? "employer" : "default",
      enableSystem: false,
      disableTransitionOnChange: true,
    }),
    [isEmployerSite]
  );

  return (
    <>
      <HeadTags {...props} />
      <EmployerSiteContextProvider value={{ isEmployerSite }}>
        <WifSavedItemsToStorageProvider>
          <ViewportContextProvider>
            <PageContentContextProvider value={page}>
              <WifAppProvider
                LinkComponent={Link}
                ImageComponent={Image}
                isEmployerSite={isEmployerSite}
              >
                <ThemeProvider {...themeProviderConfig}>
                  <RouteProgressBar />
                  <Navigation
                    links={links.primary}
                    switchLinks={links.switchLinks}
                  />
                  <main className={styles.layout}>{children}</main>
                  <Footer {...links} />
                </ThemeProvider>
              </WifAppProvider>
              <AinoChatButton />
            </PageContentContextProvider>
          </ViewportContextProvider>
        </WifSavedItemsToStorageProvider>
      </EmployerSiteContextProvider>
    </>
  );
}

function HeadTags({ title, isEmployerSite, page, isHomepage }: ILayoutProps) {
  const { locale, asPath, isPreview } = useRouter();

  const metaTitle = isHomepage ? title : `${title}${META_TITLE_SUFFIX}`;
  const description = page?.metaDescription || page?.description;
  const canonicalUrl = `${__APP_HOST_PUBLIC__}/${locale}${asPath}`;

  const ogLocale = locale ? ogLocaleMap[locale] : undefined;
  const alternateLocales = (
    isEmployerSite ? localesEmployers : localesTalent
  ).filter((l) => l !== locale);

  const ogImage =
    page?.introductionImage?.renditions?.xs?.link ||
    page?.introductionImage?.["@link"];

  const ogType =
    page?.["mgnl:template"] === MagnoliaTemplate.PageArticle ||
      page?.["mgnl:template"] === MagnoliaTemplate.PageEvent
      ? "article"
      : "website";

  const mgnlPageEditorStylesheet = isPreview // only needed in Mgnl Editor
    ? `${MGNL.BASE_URL(isPreview)}/VAADIN/themes/pages-app/page-editor.css`
    : undefined;

  return (
    <Head>
      <title>{metaTitle}</title>
      <link rel="canonical" href={canonicalUrl} />
      <link rel="preconnect" href={MGNL.HOST(isPreview)} />
      <meta name="description" content={description} />
      <meta name="keywords" content={page?.keywords?.join(", ")} />
      <meta name="msvalidate.01" content="74D640CD1F31D68F74FEE432FDD5A501" />
      {/* Open graph tags */}
      <meta
        property="og:site_name"
        content={META_TITLE_SUFFIX.replace("-", "").trim()}
      />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content={ogLocale} />
      {alternateLocales.map((l) => (
        <meta key={l} property="og:locale:alternate" content={ogLocaleMap[l]} />
      ))}
      <meta property="og:type" content={ogType} />
      {ogImage && (
        <meta
          property="og:image"
          content={formatAssetLink(ogImage, isPreview)}
        />
      )}
      {/* Mgnl styles */}
      {mgnlPageEditorStylesheet && (
        <link rel="stylesheet" href={mgnlPageEditorStylesheet} />
      )}
    </Head>
  );
}
